<template>
  <div class="wrapper" style="background-repeat: no-repeat;
  background-position: right top; background-attachment: fixed;"> 
    <div>
   <!-- <div style="background-image: url(https://res.cloudinary.com/persystance-network/image/upload/q_auto/v1589124594/slt/portal_user/1589124594178.png);
  background-repeat: no-repeat; background-attachment: fixed;"> -->
      <div class="main-panel">
        <top-navbar></top-navbar>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <dashboard-content></dashboard-content>
            </div>
          </div>
        </div>
        <content-footer  class="footer-container typefooter-1"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavbar from './DeliverDashboard/TopNavbar.vue'
import DashboardContent from './DeliverDashboard/Content.vue'
import ContentFooter from './DeliverDashboard/ContentFooter.vue'
export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter
  },
  methods: {
  }
}
</script>
