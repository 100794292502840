<template>
<!-- <div class="content well" style="min-height: 686px;"> -->
  <div class="content " :style="styleObject">
    <transition name="fade" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
  export default {
    data(){
        return{
          styleObject: {
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: "10px 10px 10px 10px"
          }
        }
    },
    computed: {
      currentRouteName() {
          return this.$route.path;
      }
    },
    onIdle () {
      this.$swal.fire({
          title: 'Your session is about to expire',
          text: "You will be logged out in 10 seconds. Do you want to stay signed in?",
          type: 'warning',
          confirmButtonText: 'Yes, keep me signed in!',
          timer: 10000
        }).then((result) => {
          if (result.value) {
          } else {
            
              if ('order_dispatch_id' in localStorage) {
                localStorage.removeItem("order_dispatch_id");
              }
              if ('deliver_order_group_id' in localStorage) {
                localStorage.removeItem("deliver_order_group_id");
              }
              this.$store.dispatch("logoutdeliver");
              //   this.$router.push('/').catch(err => {});
              this.$router.push('/deliver-login').catch(err => {});

          }
        })
    },
    created(){
      this.setBorder();
    },
    methods:{
      setBorder: function(){
          if(this.currentRouteName=="/deliver-login" || this.currentRouteName=="/deliver-register"){
              this.styleObject.border = "";
          }else{
              this.styleObject.border = "border: 1px solid #ccc";
          }
      }

    }


  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
 .content-inner{
    min-height: 535px !important;
  }
  .table{
    margin-bottom: 0px !important;
  }
  .pagination{
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 991px){
    .chartPanel{
      height: auto !important;
    }

  }

</style>
