<template>
  <div>
    <div class="footer-middle">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-infos">
                    <div class="infos-footer">
                        <a href="#"><img src="assets/image/catalog/logo-footer.png" alt="image"></a>
                        <ul class="menu">
                            <li class="adres">
                                San Luis potosí, centro historico, 78000 san luis potosí, SPL, Mexico
                            </li>
                            <li class="phone">
                                (+0214)0 315 215 - (+0214)0 315 215
                            </li>
                            <li class="mail">
                                <a href="mailto:contact@opencartworks.com">contact@opencartworks.com</a>
                            </li>
                            <li class="time">
                                Open time: 8:00AM - 6:00PM
                            </li>
                        </ul>
                    </div>
          </div>-->
          <!-- <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-style">
                    <div class="box-information box-footer">
                        <div class="module clearfix">
                            <h3 class="modtitle">Make Money With Us</h3>
                            <div class="modcontent">
                                <ul class="menu">
                                    <li><a href="#">Open Store</a></li>
                                    <li><a href="#">Advertise With Us</a></li>
                                    <li><a href="#">Partnership</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
          </div>-->
          <!-- <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-style">
                    <div class="box-information box-footer">
                        <div class="module clearfix">
                            <h3 class="modtitle">Customer Service</h3>
                            <div class="modcontent">
                                <ul class="menu">
                                    <li><a href="#">Store Agreements</a></li>
                                    <li><a href="#">Service Agreements</a></li>
                                    <li><a href="#">Surveys</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
          </div>-->
          <div class="col-lg-4 col-md-4 col-sm-4 col-style">
            <div class="box-account box-footer">
              <div class="module clearfix">
                <h3 class="modtitle">About</h3>
                <div class="modcontent">
                  <ul class="menu">
                    <!-- <li><a href="#">About Us</a></li>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Terms And Conditions</a></li>-->
                    <li>
                      <a @click="navigateToAboutUs">About Us</a>
                    </li>
                    <li>
                      <a @click="navigateToContactUs">Contact Us</a>
                    </li>
                    <li>
                      <a href="./M.PrivacyPolicy.html" target="_blank">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="./M.TermsAndConditions.html"  target="_blank"> Terms and Conditions</a>
                      <!-- <a @click="openTerms">Terms And Conditions</a> -->
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 payment-method-block">
            <div class="box-account box-footer">
              <div class="module clearfix">
                <h3 class="modtitle">Payment Methods</h3>
                <div class="pay-icons-block">
                  <img src="assets/image/paymethods/visa_logo.png" alt="Visa" />
                  <img src="assets/image/paymethods/mastercard_logo.png" alt="Master Card" />
                  <img src="assets/image/paymethods/cod_logo.png" alt="Cash on Delivery" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-clear">
                    <div class="box-service box-footer">
                        <div class="module clearfix">
                        <h3 class="modtitle">Help</h3>
                        <div class="modcontent">
                            <ul class="menu">
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
          </div>-->
          <!-- <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 col-style"> -->
          <!-- <div class="box-service box-footer">
                        <div class="module clearfix">
                            <h3 class="modtitle">Services</h3>
                            <div class="modcontent">
                                <ul class="menu">
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Returns</a></li>
                                    <li><a href="#">Support</a></li>
                                    <li><a href="#">Site Map</a></li>
                                    <li><a href="#">Customer Service</a></li>
                                    <li><a href="#">Custom Link</a></li>
                                </ul>
                            </div>
                        </div>
          </div>-->
          <!-- </div> -->
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-style">
            <div class="box-service box-footer">
              <div class="module clearfix">
                <h3 class="modtitle">Stay Connected</h3>
                <div class="row del-part-socialmed" style="font-size:25px;">
                  <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.facebook.com/cochchi/?hc_ref=ARSElkE1HasC78yVQVJTivh69s3cX2ZZQ0ocDJX6xtKnjqkfS9n3k9AAsItZRR3O4xw&fref=nf&__tn__=kC-R" target="_blank"><i class="fa fa-facebook"></i></a></div>
                  <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.instagram.com/p/CRGfn4glwbW/" target="_blank"><i class="fa fa-instagram"></i></a></div>
                  <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.youtube.com/channel/UCBIidMSwds9wf5AAmW_ABQQ" target="_blank"><i class="fa fa-youtube-play"></i></a></div>
                  <!-- <div class="col-md-1 col-sm-1 col-xs-2 col-2">
                    <a href="https://www.facebook.com/lankasathosaofficial/" target="_blank">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </div>
                  <div class="col-md-1 col-sm-1 col-xs-2 col-2">
                    <a
                      href="https://www.youtube.com/channel/UC9xiJbEa6AznOz-oMmodgVQ"
                      target="_blank"
                    >
                      <i class="fa fa-youtube-play"></i>
                    </a>
                  </div> -->
                  <!-- <div class="col-md-2 col-sm-2 col-xs-2 col-2"><a href="" ><i class="fa fa-twitter"></i></a></div> -->
                  <!-- <div class="col-md-2 col-sm-2 col-xs-2 col-2"><a href="" ><i class="fa fa-linkedin"></i></a></div> -->
                  <!-- <div class="col-md-2 col-sm-2 col-xs-2 col-2"><a href="" ><i style="font-weight: bold;" class="fa fa-instagram"></i></a></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="footer-b">
          <div class="bottom-cont deliveryftr-bottom-container">
            <h4 style="margin-bottom:15px;">Download The App</h4>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6" style="text-align:right;">
                <img data-v-9587db1e="" src="/assets/image/playstore.png" class="store-img-playstore">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6" style="text-align:left;">
                <img data-v-9587db1e="" src="/assets/image/appstore.png" class="store-img-playstore">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="footer-bottom">
      <div class="container-fluid" style="padding-left: 0px;padding-right: 0px;">
        <div class="col-lg-12 col-xs-12 payment-w sathosa-footer-logo">
          <img
            :src="this.$store.state.whiteLogo"
            style="width: 100px;height: auto;"
            alt="imgpayment"
          />
        </div>
      </div>
      <div id="deliver-copyright-w" class="copyright-w" style="margin-top: 0px;">
        <div class="container">
          <div class="copyright">
            <span class="delftrlogoleft">
              <!-- <img
                class="supiripola-log-in-sathosa"
                src=""
              /> -->
            </span>
            <span>©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme</span>
            <span class="delftrlogoright">
              <!-- <img
                class="slt-logo-insathosa"
                src=""
              /> -->
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="back-to-top">
      <i class="fa fa-angle-up"></i>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    navigateToContactUs() {
      this.$router.push("/merchant-contact-us");
    },
    navigateToAboutUs() {
      this.$router.push("/merchant-about-us");
    },
    openTerms() {
      this.$router.push({ name: "TermsAndConditions" });
    },
    openPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
    }
  }
};
</script>
<style>
.footer-uncommon-features {
  background-color: #e7e7e7;
  border-bottom: 30px;
}
#deliver-copyright-w {
  background-color: none;
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
}
.typefooter-1 .module .modtitle:after {
  background: #ffd338;
}
.typefooter-1 .box-footer .modcontent ul li a:hover {
  color: #ffd338;
}
.del-part-socialmed .fa:hover {
  color: #ffd338;
}
.payment-method-block {
  text-align: left;
  color: #222222;
}
.payment-method-block h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 15px;
}
.payment-method-block h3:after {
  background: #ffd338;
  position: absolute;
  content: "";
  width: 70px;
  height: 2px;
  left: 0;
  bottom: 0;
}
.payment-method-block .pay-icons-block img {
  display: inline-block;
  margin-right: 10px;
}
.sathosa-footer-logo {
  background: #5779ae;
  padding-top: 12px;
  padding-bottom: 12px;
}
.supiripola-log-in-sathosa {
  display: inline-block;
  width: 7.5%;
  margin-left: 5px;
  margin-right: 5px;
}
.slt-logo-insathosa {
  display: inline-block;
  width: 2%;
  margin-left: 5px;
  margin-right: 5px;
}
/* New media queries */
@media screen and (max-width: 768px) {
  .box-footer {
    text-align: center;
    padding-bottom: 15px;
  }
  .box-footer .menu a {
    padding-left: 0px !important;
  }
  .box-footer .menu a:before {
    display: none;
  }
  .box-footer h3 {
    text-align: center;
  }
  .box-footer h3:after {
    display: none;
  }
  .del-part-socialmed {
    display: flex;
    justify-content: space-around;
  }
}
@media screen and (max-width: 730px) {
  .delftrlogoleft {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }
  .delftrlogoleft img {
    width: 30%;
  }
  .delftrlogoright {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
  .delftrlogoright img {
    width: 10%;
  }
}

@media screen and (max-width: 475px) {
  .deliveryftr-bottom-container{
    width: 100% !important;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}


</style>
