<template>
  <div>
    <div id="DeliverWrapper" style="background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%) !important" class="wrapper-fluid banners-effect-3">
      <header id="header" style class="typeheader-1 typeheader-2 deliver-new-hdr">
        <!-- <div class="header-top hidden-compact">
            <div class="container">
                <div class="row">
                    <div class="header-top-left col-lg-7 col-md-8 col-sm-6 col-xs-4">
                        <ul class="top-link list-inline hidden-lg ">
                            <li class="account" id="my_account">
                                <a title="My Account " class="btn-xs dropdown-toggle" data-toggle="dropdown"> <span class="hidden-xs">&nbsp;My Account 123 </span>&nbsp;&nbsp;<span class="fa fa-caret-down"></span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li v-if="this.$store.state.deliver_accessToken !== null">
                                        <a @click="pushdeliverProfile"><i class="fa fa-user"></i>&nbsp;&nbsp;Profile</a>
                                    </li>
                                    
                                    <li v-if="this.$store.state.deliver_accessToken === null" class="log login">
                                        <a @click="login"><i class="fa fa-pencil-square-o"></i> &nbsp;&nbsp;Login</a>
                                    </li>

                                    <li v-if="this.$store.state.deliver_accessToken === null" class="log login">
                                        <a @click="register"><i class="fa fa-user"></i> &nbsp;&nbsp;Register</a>
                                    </li> 

                                    <li v-if="this.$store.state.deliver_accessToken !== null">
                                        <a @click="logout"><i class="fa fa-lock"></i>&nbsp;&nbsp;Log Out</a>
                                    </li> 
                                    
                                </ul>
                            </li>
                        </ul>  
                    </div>
                    <div class="header-top-right col-lg-5 col-md-4 col-sm-6 col-xs-8">
                        <div class="hidden-md hidden-sm hidden-xs welcome-msg buyon-text" @click="handleByOnSlt" ><a> Buy on SLT</a></div>       
                    </div>
                </div>
            </div>
        </div>-->

        <!-- Header middle starts -->
        <div
          class="header-middle deliver-header-middle"
          style="margin-top: 0px;margin-bottom: 0px;adding-top: 0pxpadding-bottom: 0px"
        >
          <div
            class="container-fluid"
            style="min-height: 90px;padding-left: 0px;padding-right: 0px;"
          >
            <!-- <div class="navbar-logo col-lg-3 col-md-3 col-sm-4 col-xs-7" style="position: absolute;">
                    <div style=" margin-left: -30px;">
                        <nav class="navbar navbar-dark" style="margin-left: -30px; margin-top: 0px; height: 66px;">
                            <a class="navbar-brand" @click="handleByOnSlt()">
                            <img src="https://res.cloudinary.com/persystance-network/image/upload/v1589092077/slt/portal_user/1589092077321.png" alt="mdb logo" style="margin-top: -16px; margin-left: 3%; width: 79%; height: auto;min-height: 105px;">
                            </a>
                        </nav>
                    </div>
            </div>-->

            <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12 delivery-logo-section">
              <div id="">
                <a @click="handleByOnSlt()">
                  <img
                    :src="this.$store.state.colourLogo"
                    alt="mdb logo"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-7 col-xs-12  header-portal-title deliver-header-portal-title">
              <B>Cochchi Delivery Partner</B>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs deliver--righttext-wpr">
              <img src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629971358/cochchi/mercahnt/1629971355957.png" alt="Your Store" />
            </div>
          </div>
        </div>
        <!-- Header middle ends -->

        <div class="header-bottom hidden-compact deliver-header-bottom" style="margin-top: -10px;">
          <div class="container">
            <div class="row">
              <div class="bottom3 deliver-header-link-text">
                <div
                  class="signin-w"
                  v-if="this.$store.state.deliver_accessToken != null"
                >
                  <ul class="signin-link blank merch-top-links">
                    <li class="log login deliver-logout">
                      <i class="fa fa-unlock text-color-headder"></i>
                      <a class="link-lg text-color-headder" @click="logout">
                        <B class="text-color-headder">Log Out</B>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  class="signin-w"
                  v-if="this.$store.state.deliver_accessToken != null"
                >
                  <ul class="signin-link blank merch-top-links">
                    <li 
                      v-if="this.$store.state.deliver_accessToken !== null"
                      class="deliver-profile"
                    
                    >
                      <a @click="pushdeliverProfile">
                        <i class="fa fa-user text-color-headder"></i>&nbsp;&nbsp;
                        <B class="text-color-headder">Profile</B>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  data() {
    return {
      loggedin: false
    };
  },
  methods: {
    handleByOnSlt: function() {
      this.$router.push("/deliver-order-group").catch(err => {});
    },
    handleByOnSltlanding: function() {
      this.$router.push("deliver-landing").catch(err => {});
    },
    handledeliverProfile: function() {
      this.$router.push("profile-configure").catch(err => {});
    },
    login() {
      this.$router.push("/deliver-login").catch(err => {});
    },
    logout() {
      if ("order_dispatch_id" in localStorage) {
        localStorage.removeItem("order_dispatch_id");
      }
      if ("deliver_order_group_id" in localStorage) {
        localStorage.removeItem("deliver_order_group_id");
      }
      this.$store.dispatch("logoutdeliver");
      //   this.$router.push('/').catch(err => {});
      this.$router.push("/deliver-login").catch(err => {});
    },
    register() {
      this.$router.push("/deliver-register").catch(err => {});
    },
    pushdeliverProfile() {
      this.$router.push("/deliver-profile").catch(err => {});
    },
    deliverLanding() {
      this.$router.push("/deliver-profile").catch(err => {});
    }
  }
};
</script>
<style scoped>
.delivary-logo {
  padding-top: 15px;
}
.delpart-links li a:hover {
  color: #ffd338;
}
.fa-unlock:hover {
  color: #ffd338;
}
.buyon-text a:hover {
  color: #ffd338;
}
.logo-bg {
  position: absolute;
  background-color: #de1a22;
  left: -60px;
  padding-left: 50px;
  top: 0px !important;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 80px;
  border-bottom-right-radius: 90px;
}
.text-color-headder {
  color: #ffffff;
}
.text-color-headder:hover{
  color: #000000;
}
.logo-bg img {
  width: 100%;
}
.header-portal-title {
  text-align: right;
  padding-top: 20px;
  font-size: 17px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 0.07345em;
}

/* New header styles */
/* .newlogo-block {
  overflow: hidden;
  padding-left: 0px;
} */
/* #delparthdrlogo {
  width: 100%;
  height: 90px;
  margin: 0 0 0 -20px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: #de1a22;
  overflow: hidden;
  position: relative;
  outline: 1px solid red;
}
#delparthdrlogo img {
  display: block;
  width: 45%;
  margin: 14px auto 0 auto;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
} */
#DeliverWrapper {
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%) !important;
  min-height: 147px;
}

.delivery-logo-section {
  height: 147px;
  padding-left: 2.5%;
  padding-top: 1%;
  background: url("https://res.cloudinary.com/persystance-network/image/upload/v1608183515/cochchi/portal_user/1608183514902.png") no-repeat left center !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.deliver--righttext-wpr{
  height: 147px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-end;
}

.deliver-header-middle{
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%) !important ;
  /* background: #5779ae; */
  padding-top: 0px;
  padding-bottom: 0px;
}
.deliver-new-hdr{
  /* background: #5779ae !important; */
  margin-bottom: 0px !important;
}
.delivery-logo-section img{
  width: 22% !important;
}
.typeheader-1 .header-bottom .container .deliver-header-link-text{
  width: 100%;
  padding-right: 15px;
}
.typeheader-1 .header-bottom .container .deliver-header-link-text .signin-w ul li a{
  font-size: 14px;
  color: #ffffff;
}
.typeheader-1 .header-bottom .container .deliver-header-link-text .signin-w ul .deliver-logout .fa{
  margin-right: 7px;
}
.typeheader-1 .header-bottom .container .deliver-header-link-text .signin-w ul .deliver-profile .fa{
   margin-right: 7px;
}
.typeheader-2 .deliver-header-bottom{
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
}

.typeheader-1 .header-bottom .container .bottom3 {
    position: absolute;
    bottom: 8px;
    right: 12%;
}

@media screen and (max-width: 1700px) {
  .typeheader-1 .header-bottom .container .bottom3 {
    right: 15%;
  }
}

@media screen and (max-width: 1650px) {
  .delivery-logo-section img{
    width: 25% !important;
  }
  .delivery-logo-section {
    padding-left: 3%;
    padding-top: 1%;
  }

  /* .typeheader-1 .header-bottom .container .bottom3 {
    right: 7%;
  } */
}

@media screen and (max-width: 1550px) {
  .delivery-logo-section img{
    width: 27% !important;
  }
}

@media screen and (max-width: 1500px) {
  .delivery-logo-section img{
    width: 29% !important;
  }
}

@media screen and (max-width: 1440px) {
  .delivery-logo-section img{
    width: 31% !important;
  }

  .delivery-logo-section {
    padding-left: 4%;
    padding-top: 1%;
  }
}

@media screen and (max-width: 1280px) {
  .delivery-logo-section img{
    width: 34% !important;
  }
}

@media screen and (max-width: 1200px) {
  .delivery-logo-section img{
    width: 36% !important;
  }
}

@media screen and (max-width: 1024px) {
  .delivery-logo-section {
    padding-left: 4%;
    padding-top: 1%;
  }

  .delivery-logo-section img{
    width: 40% !important;
    margin-top: 16px;
    margin-left: 14px;
  }
}

@media screen and (max-width: 991px) {
  .buyer-header-middle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #delparthdrlogo img {
    width: 55%;
  }

  header.typeheader-2 .header-middle {
    padding: 0px;
  }

  .delivery-logo-section img{
    margin-top: 0px;
    margin-left: 14px;
  }

  .header-portal-title {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  /* .delivery-logo-section{
    width: 100%;
  }
  .delivery-logo-section{
    text-align: center;
  }
  .delivery-logo-section img{
    width: 20% !important;
  } */
  .deliver-header-portal-title{
    width: 100%;
    padding-right: 4%;
  }
  .typeheader-1 .header-bottom .container .deliver-header-link-text{
    padding-right: 0px;
    position: relative;
    padding-right: 30%;
  }
  .deliver-header-portal-title{
    text-align: center;
    margin-top: 25px;
  }
}
@media screen and (max-width: 875px) {
  /* #delparthdrlogo img {
    margin: 17px auto 0 auto;
  } */
}
@media screen and (max-width: 768px) {
  .buyer-header-middle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .delivery-logo-section img{
    width: 46% !important;
  }

  .delivery-logo-section img {
    margin-top: 7px;
    margin-left: 14px;
  }
  /* #delparthdrlogo img {
    width: 50%;
  } */
  /* .newlogo-block {
    width: 40%;
  } */
  /* .header-portal-title {
    width: 60%;
  } */
}
@media screen and (max-width: 767px) {
  .buyer-header-middle {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .delivery-logo-section img{
    width: 20% !important;
  }

  /* #delparthdrlogo img {
    width: 50%;
  } */
  /* .newlogo-block {
    width: 40%;
  } */
  /* .header-portal-title {
    width: 60%;
  } */
}
@media screen and (max-width: 575px) {
  /* #delparthdrlogo img {
    width: 55%;
  } */
  /* .newlogo-block {
    width: 45%;
  } */
  /* .header-portal-title {
    width: 55%;
    font-size: 15px;
  } */
  .delivery-logo-section img{
    width: 27% !important;
  }
  .deliver-header-portal-title{
    margin-top: 15px;
  }
}

@media screen and (max-width: 475px) {
  .typeheader-1 .header-bottom .container .deliver-header-link-text{
    padding-right: 25%;
  }
  .delivery-logo-section img{
    width: 31% !important;
    margin-left: 0px;
    margin-top: 0px;
  }
  .delivery-logo-section {
    height: 130px;
    background-color: white !important;
    text-align: center;
    padding-left: 0px;
  }
}

@media screen and (max-width: 425px) {
  #delparthdrlogo img {
    width: 55%;
    margin: 20px auto 0 auto;
  }

  .typeheader-1 .header-bottom .container .deliver-header-link-text{
    padding-right: 22%;
  }

  .delivery-logo-section img {
    margin-top: 5px;
  }
  
}
@media screen and (max-width: 375px) {
  .delivery-logo-section img {
    margin-top: 10px;
  }

  .delivery-logo-section img {
    width: 34% !important;
    margin-left: 0px;
  }
}
@media screen and (max-width: 350px) {
  .typeheader-1 .header-bottom .container .deliver-header-link-text{
    padding-right: 20%;
  }
  /* .newlogo-block {
    width: 100%;
    padding-right: 0px;
  } */
  /* .header-portal-title {#delparthdrlogo
    width: 100%;#delparthdrlogo
    text-align: center;#delparthdrlogo
    padding-bottom: 15px;
  } */
  /* #delparthdrlogo {
    margin-left: 0px;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
  #delparthdrlogo img {
    display: block;
    width: 40%;
    margin: 17px auto 0 auto;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  } */
}
@media screen and (min-width: 1575px) {
  /* #delparthdrlogo img {
    width: 40%;
  } */
}
@media screen and (min-width: 1675px) {
  /* .newlogo-block {
    width: 20%;
  } */
  /* .header-portal-title {
    width: 80%;
  } */
}
@media screen and (min-width: 1775px) {
  /* #delparthdrlogo img {
    margin: 14px auto 0 auto;
  } */
}
@media screen and (min-width: 1920px) {
  /* #delparthdrlogo img {
    width: 42%;
    margin: 12px auto 0 auto;
  } */
}
@media screen and (min-width: 2000px) {
  /* #delparthdrlogo img {
    width: 35%;
    margin: 15px auto 0 auto;
  } */
}
@media screen and (min-width: 2300px) {
  /* #delparthdrlogo img {
    width: 32%;
    margin: 16px auto 0 auto;
  } */
}
</style>

