var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer-middle"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-style"},[_c('div',{staticClass:"box-account box-footer"},[_c('div',{staticClass:"module clearfix"},[_c('h3',{staticClass:"modtitle"},[_vm._v("About")]),_c('div',{staticClass:"modcontent"},[_c('ul',{staticClass:"menu"},[_c('li',[_c('a',{on:{"click":_vm.navigateToAboutUs}},[_vm._v("About Us")])]),_c('li',[_c('a',{on:{"click":_vm.navigateToContactUs}},[_vm._v("Contact Us")])]),_vm._m(0),_vm._m(1)])])])])]),_vm._m(2),_vm._m(3)])])]),_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container-fluid",staticStyle:{"padding-left":"0px","padding-right":"0px"}},[_c('div',{staticClass:"col-lg-12 col-xs-12 payment-w sathosa-footer-logo"},[_c('img',{staticStyle:{"width":"100px","height":"auto"},attrs:{"src":this.$store.state.whiteLogo,"alt":"imgpayment"}})])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./M.PrivacyPolicy.html","target":"_blank"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./M.TermsAndConditions.html","target":"_blank"}},[_vm._v(" Terms and Conditions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 payment-method-block"},[_c('div',{staticClass:"box-account box-footer"},[_c('div',{staticClass:"module clearfix"},[_c('h3',{staticClass:"modtitle"},[_vm._v("Payment Methods")]),_c('div',{staticClass:"pay-icons-block"},[_c('img',{attrs:{"src":"assets/image/paymethods/visa_logo.png","alt":"Visa"}}),_c('img',{attrs:{"src":"assets/image/paymethods/mastercard_logo.png","alt":"Master Card"}}),_c('img',{attrs:{"src":"assets/image/paymethods/cod_logo.png","alt":"Cash on Delivery"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 col-sm-4 col-xs-12 col-style"},[_c('div',{staticClass:"box-service box-footer"},[_c('div',{staticClass:"module clearfix"},[_c('h3',{staticClass:"modtitle"},[_vm._v("Stay Connected")]),_c('div',{staticClass:"row del-part-socialmed",staticStyle:{"font-size":"25px"}},[_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.facebook.com/cochchi/?hc_ref=ARSElkE1HasC78yVQVJTivh69s3cX2ZZQ0ocDJX6xtKnjqkfS9n3k9AAsItZRR3O4xw&fref=nf&__tn__=kC-R","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook"})])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.instagram.com/p/CRGfn4glwbW/","target":"_blank"}},[_c('i',{staticClass:"fa fa-instagram"})])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.youtube.com/channel/UCBIidMSwds9wf5AAmW_ABQQ","target":"_blank"}},[_c('i',{staticClass:"fa fa-youtube-play"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright-w",staticStyle:{"margin-top":"0px"},attrs:{"id":"deliver-copyright-w"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"copyright"},[_c('span',{staticClass:"delftrlogoleft"}),_c('span',[_vm._v("©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme")]),_c('span',{staticClass:"delftrlogoright"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-to-top"},[_c('i',{staticClass:"fa fa-angle-up"})])
}]

export { render, staticRenderFns }